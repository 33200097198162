<template>
    <v-container class="pt-0">
     <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="330" min-width="330">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dates" label="Selecciona un rango de fechas" hide-details="" filled dense
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker range v-model="dates" scrollable :min="dates.length > 0 ? dates[0] : null" width="100%">
                <v-spacer></v-spacer>
                <v-btn text color="primary darken-1" @click=" dates = []">Cancelar</v-btn>
                <v-btn text color="primary" @click="menu = false; fetchMetrics()">Confirmar</v-btn>
            </v-date-picker>
        </v-menu>
        <v-progress-linear v-if="loading"   color="primary" indeterminate></v-progress-linear>

        <v-tabs   grow>
            <v-tab>
                <v-icon left>
                    mdi-chart-bar
                </v-icon>
                Resultados
            </v-tab>
            <v-tab :disabled="users.length==0"> 
                <v-icon left>
                    mdi-information
                </v-icon>
                Bajas/Motivos
            </v-tab>

            <v-tab :disabled="reEnabledUsers.length==0">
                <v-icon left>
                    mdi-information
                </v-icon>
                Re-activaciones
            </v-tab>

            <v-tab-item>
                <v-row class="mt-4">
                    <v-col cols="12" >
                        <v-row >
                             <v-col cols="12" md="4" order-md="last">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card class="pa-0" color="green" dark>
                                            <v-card-title class="text-h5">Nuevos</v-card-title>
                                            <v-card-text class="text-h3" v-if="chartSeries.length > 0 &&  !loading ">{{ chartSeries[0].data[chartSeries[0].data.length - 1] }}</v-card-text>
                                            <v-card-text class="text-h3" v-else><v-progress-circular size="40" indeterminate color="white"></v-progress-circular></v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card class="pa-0" color="error" dark>
                                            <v-card-title class="text-h5">Bajas</v-card-title>
                                            <v-card-text class="text-h3" v-if="chartSeries.length > 0 &&  !loading ">{{ chartSeries[1].data[chartSeries[1].data.length - 1] }}</v-card-text>
                                            <v-card-text class="text-h3" v-else><v-progress-circular size="40" indeterminate color="white"></v-progress-circular></v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-card class="pa-0" color="primary" dark>
                                            <v-card-title class="text-h6">Altas</v-card-title>
                                            <v-card-text class="text-h3" v-if="chartSeries.length > 0 &&  !loading ">{{ chartSeries[2].data[chartSeries[2].data.length - 1] }}</v-card-text>
                                            <v-card-text class="text-h3" v-else><v-progress-circular size="40" indeterminate color="white"></v-progress-circular></v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" md="8" order-md="first">
                              <VueApexCharts v-if="chartSeries.length > 0 &&  !loading "
                                type="line"
                                :options="chartOptions"
                                :series="chartSeries"
                                height="350"
                                ></VueApexCharts>
                                <v-skeleton-loader v-else height="350" type="image"
                                ></v-skeleton-loader>
                            </v-col>
                           
                        </v-row>
                    </v-col>
                </v-row>
            </v-tab-item>
            
            <v-tab-item>

                   <v-data-table  :headers="headers" :items="users" :loading="loading" class="elevation-0" v-if="users.length > 0"  
                       mobile-breakpoint="0" sort-by="date">
                    </v-data-table>

            </v-tab-item>


            <v-tab-item>
                 <v-data-table  :headers="headers" :items="reEnabledUsers" :loading="loading" class="elevation-0" v-if="reEnabledUsers.length > 0"  
                       mobile-breakpoint="0" sort-by="date">
                    </v-data-table>
            </v-tab-item>
        </v-tabs>


        <v-dialog v-model="paymentDetails" max-width="350">
            <v-card v-if="paymentObj">
                <v-card-title>

                    Detalles del pago
                    <v-spacer></v-spacer>
                    <v-btn icon @click="paymentDetails = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p>Estado:
                        <v-chip v-if="paymentObj.status == 'Pendiente'" color="warning" dark>{{ paymentObj.status
                        }}</v-chip>
                        <v-chip v-else-if="paymentObj.status == 'Cancelado'" color="red" dark>{{ paymentObj.status
                        }}</v-chip>
                        <v-chip v-else color="green" dark>{{ paymentObj.status }}</v-chip>
                    </p>
                    <p>Id Usuario: {{ paymentObj.civilId }}</p>
                    <p>Plan: {{ paymentObj.amount }}</p>
                    <p>Fecha de cobro: {{ paymentObj.createdAt }}</p>
                    <p>Meses: {{ paymentObj.months }}</p>
                    <p>Metodo de pago: {{ paymentObj.payMethod }}</p>
                    <template v-if="paymentObj.status == 'Pendiente'">
                        <v-btn block color="red" class="mt-6" @click="updatePaymentStatus(paymentObj.id, 'Cancelado')"
                            :disabled="loading">
                            <v-icon left>mdi-close</v-icon>
                            Rechazar pago
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn block class="mt-4" color="green" @click="updatePaymentStatus(paymentObj.id, 'Confirmado')"
                            :disabled="loading">
                            <v-icon left>mdi-check</v-icon>
                            Confirmar pago
                        </v-btn>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { ref } from 'vue'
import { collection, getDocs, query, where, orderBy, startAt, endAt, getFirestore, setDoc, doc, addDoc, updateDoc, Timestamp } from "firebase/firestore";
import moment from 'moment'
import { countBy, keys, values } from 'lodash'
import VueApexCharts from "vue-apexcharts";

import { getFunctions, httpsCallable } from "firebase/functions";

export default {
    created(){
        this.chartOptions.theme.mode = this.$store.state.isDark ? 'dark' : 'light'
    },
    components: {
        "VueApexCharts": VueApexCharts,
    },
    name: 'NewSubscriptionsRange',
    data() {
        return {
        payMethodData: {},
        monthsData: {},
        amountData: {},
        statusData: {},
        menu: false,
        dates: [],
        headers: [
             { text: 'Nombre', value: 'displayName' },
            { text: 'Motivo', value: 'reason' },
            { text: 'ID ', value: 'id' },
            { text: 'Fecha', value: 'date' },
            { text: 'extID', value: 'ext_id'}
        ],
        payments: [],
        loading: false,
        paymentDetails: false,
        paymentObj: null, 
        chartSeries: [],
        chartOptions: {
             theme: {
                    mode: 'dark'
                },
            chart: {
                type: 'line',
                zoom: {
                    enabled: false
                },
                toolbar: {
                        show: false
                    }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: 'Nuevos, Bajas y Renovaciones',
                align: 'center'
            },
            xaxis: {
                type: "datetime",
                categories: [],
            },
            stroke: {
                    curve: 'smooth',
                    width: 3
            },
            markers: {
                size: 0
            },
            colors: ['#4CAF50', '#ff5252', '#2196f3'],
        },
        users: [],
        reEnabledUsers: [],
    }
    },
    mounted() {


        this.fetchMetrics()
    },
    methods: {
        processChartData(data) {
            // Assuming data format matches { newAndNotDisabled: {}, disabled: {}, reEnabled: {} }
            const categories = Object.keys(data.newAndNotDisabled);
            const newAndNotDisabledData = Object.values(data.newAndNotDisabled);
            const disabledData = Object.values(data.disabled);
            const reEnabledData = Object.values(data.reEnabled);

            this.chartOptions.xaxis.categories = categories;

            this.chartSeries = [
                { name: "Nuevos", data: newAndNotDisabledData },
                { name: "Bajas", data: disabledData },
                { name: "Renovaciones", data: reEnabledData },
            ];
        },
        async fetchMetrics() {// 'calculateCumulativeMetrics' function is called here
            try {
                this.loading = true;
                let start = null;
                let end = null;

                if (this.dates.length == 2) {
                    start = new Date(this.dates[0] + " 00:00:00")
                    end = new Date(this.dates[1] + " 23:59:59")
                } else {
                    // here start is the start of the month and end is today at 23:59:59
                    let date = moment()
                    let startOfMonth = moment().startOf('month')

                    start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                    end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                    this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
                }
            
                const functions = getFunctions();
                const calculateCumulativeMetrics = httpsCallable(functions, "calculateCumulativeMetrics");
                const result = await calculateCumulativeMetrics({
                    startDate: start,
                    endDate: end,
                });

                this.processChartData(result.data);


                const db = getFirestore()
                const q = query(
                    collection(db, "disabledUsers"),
                    where("date", ">=", start),
                    where("date", "<=", end)
                );
                const querySnapshot = await getDocs(q);
                const users = []

                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.date = moment(new Date(data.date.seconds * 1000)).format('DD/MM/YYYY')

                    users.push({  ...data, ext_id: doc.id,})
                });

                this.users = users


                // now query reEnabledUsers
                const q2 = query(
                    collection(db, "reEnabledUsers"),
                    where("date", ">=", start),
                    where("date", "<=", end)
                );
                const querySnapshot2 = await getDocs(q2);
                const reEnabledUsers = []

                querySnapshot2.forEach((doc) => {
                    let data = doc.data()
                    data.date = moment(new Date(data.date.seconds * 1000)).format('DD/MM/YYYY')

                    reEnabledUsers.push({  ...data, ext_id: doc.id,})
                });

                this.reEnabledUsers = reEnabledUsers
                






            } catch (error) {
                console.error("Error fetching metrics: ", error);
            } finally {
                this.loading = false;
            }
        }, 
    }
}
</script>
